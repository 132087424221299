
import { copySelectTextContents, MoDate } from '@mobro/libs';
import { Iobject } from '@mobro/libs/es/type';
import { IRequestMethod, request } from '@mobro/request';
import { Message } from 'element-ui';
import { Component } from 'vue-property-decorator';
import { ONLINE_HOST, QUERY_ORDER_BY_VALUE, QUERY_OSS_STORAGE_DIR, QUERY_PIC_ORDER_BY } from '@/store/constants';
import { mixins } from 'vue-class-component';
import Login from '@/mixins/login';
import { getOssUrl } from '@/libs/utils';

@Component({})
export default class PicList extends mixins(Login) {
    private picList: Iobject[] = [];
    private marker = '';
    private loading = false;
    ossStorageDirList = QUERY_OSS_STORAGE_DIR;
    ossOrderByList = QUERY_PIC_ORDER_BY;
    private formData = {
        dir: QUERY_OSS_STORAGE_DIR[0],
        orderBy: QUERY_PIC_ORDER_BY[0],
        orderByValue: QUERY_ORDER_BY_VALUE.desc,
        nowFileName: '',
    };

    mounted() {
        // this.getList();
    }

    getList() {
        if (this.marker === null) return;

        this.loading = true;
        request(
            {
                url: `${ONLINE_HOST}/api/common/file/list`,
                method: IRequestMethod.GET,
                needAllData: true,
                params: {
                    marker: this.marker,
                    pageSize: 30,
                    prefix: this.formData.dir,
                    orderByValue: this.formData.orderByValue,
                    orderBy: this.formData.orderBy,
                    nowFileName: this.formData.nowFileName,
                    token: this.token,
                },
            },
            {
                withCredentials: false,
            },
        )
            .then((res: Iobject) => {
                this.picList = this.picList
                    .concat(res.data || [])
                    .map(pic => ({ ...pic, url: getOssUrl(`${pic.dir}/${pic.nowFileName}`) }));
                this.marker = res.marker;
            })
            .finally(() => {
                this.loading = false;
            });
    }

    async onCopy(pic: Iobject) {
        await copySelectTextContents(pic.url);
        Message.success('图片地址复制成功！！');
    }

    onDirChange(v: string) {
        this.loading = false;
        this.picList = [];
        this.formData = {
            dir: v,
            orderBy: QUERY_PIC_ORDER_BY[0],
            orderByValue: QUERY_ORDER_BY_VALUE.desc,
            nowFileName: '',
        };
        // this.getList();
    }

    onSubmit() {
        this.loading = false;
        this.picList = [];
        this.marker = '';
        this.getList();
    }

    get disabled() {
        return this.loading || this.marker === null;
    }

    get picPreviewList() {
        return this.picList.map(pic => pic.url);
    }

    picSize(size: number) {
        if (size < 1024) {
            return `${size}B`;
        } else if (size / 1024 < 1024) {
            return `${Math.floor(size / 1024)}KB`;
        } else {
            return `${Math.floor((size * 100) / 1024 / 1024) / 100}MB`;
        }
    }

    picUpdateTime(time: number) {
        return MoDate.format('YYYY-MM-DD', time);
    }
}
